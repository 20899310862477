export function formatPhoneNumber(value: string | number) {
	const formattedValue = typeof value === 'number' ? value.toString() : value;

	if (!formattedValue) return formattedValue;

	const phoneNumber = formattedValue.replace(/[^\d]/g, '');

	const phoneNumberLength = phoneNumber.length;

	if (phoneNumberLength < 4) return phoneNumber;

	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}
