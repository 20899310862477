import { DrivenButton, DrivenLocationCard } from '@drivenbrands/driven-components';

import { captureCustomDataLayerEvent, Store } from '../../../../services';
import { formatPhoneNumber } from '../../../../utils';
import { SelectionButton } from './selection-button';

type StoreResultCardProps = {
	isSelected?: boolean;
	store: Store;
	handleStoreSelection: (store: Store) => void;
};

// if on list of test oet stores then show call button.  Do not allow user to continue for quote.
type TestStores = {
	[key: number]: string;
};

const testStores: TestStores = {
	27: '314-888-9941',
	80: '816-844-3116',
	82: '417-771-3162',
	84: '402-718-8178',
	88: '319-382-6204',
	94: '913-956-0705',
	96: '412-944-2767',
	309: '563-551-2555',
	323: '224-231-0728',
	46: '330-779-1163',
	49: '216-815-4023',
	55: '317-982-5809',
	56: '317-983-6426',
	61: '412-947-4049',
	62: '937-949-1120',
	95: '812-618-4698',
};

function TestValue(storeNum: number) {
	const result = testStores[storeNum];
	return result || null;
}

export const StoreResultCard = ({
	isSelected,
	store,
	handleStoreSelection,
}: StoreResultCardProps) => {
	const storeID = Number(store.ShopId);

	const storeButton = () => {
		// console.log('test store value 1 >> ', TestValue(90)) //should be null
		// console.log('test store value 2 >> ', TestValue(80)) //should return phone number
		// console.log('store id >> ', storeID);
		const callButton = TestValue(storeID);

		if (callButton) {
			return (
				<DrivenButton
					variant='outlined'
					href={`tel:${formatPhoneNumber(callButton)}`}
					onClick={() => {
						captureCustomDataLayerEvent('phone_number_click', {
							request_quote_cta_location: 'test_store_list',
						});
					}}
				>
					For a quote call
					<br />
					{formatPhoneNumber(callButton)}
				</DrivenButton>
			);
		}

		return (
			<SelectionButton
				isSelected={Boolean(isSelected)}
				onClick={() => handleStoreSelection(store)}
			/>
		);
	};

	return (
		<DrivenLocationCard
			actions={storeButton()}
			data-store-id={store.ShopId}
			mobileButtonColor='inherit'
			storeDetails={{
				store_id: Number(store.ShopId),
				distance: store.Distance,
				store_address: store.Address,
				store_city: store.City,
				store_state: store.State,
				store_postcode: store.Zip,
			}}
			handleStoreSelection={() => {
				captureCustomDataLayerEvent('store_location_click');
			}}
			variant='outlined'
			simplified
			title={store.City}
			titleVariant='subtitle1'
			sx={{ xs: 0, sm: -1 }}
		/>
	);
};
