import {
	DrivenTypography,
	IconPrefix,
	IconTypes,
} from '@drivenbrands/driven-components';

export const createBulletedListItems = (list: Array<string>) => {
	return list.map((item) => {
		return {
			icon: IconTypes.CHECK,
			iconPrefix: IconPrefix.fal,
			text: <DrivenTypography variant='body2'>{item}</DrivenTypography>,
		};
	});
};

export const createDropdownOptions = (data: Array<any>) => {
	return data.map((item) => ({ option: item, value: item }));
};

